
.@{class-prefix}-home-header{
    .@{class-prefix}-card-menu{
      margin-top: -80px;
    }
  
    @media @sm {
        .@{class-prefix}-app-menu span{
            font-size: 12px;
        }
    }
}

.@{class-prefix}-header-menu{
    border:1px solid #A0A3BD;
    border-radius: 100%;
    padding: 10px;
    aspect-ratio: 1 / 1;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
  }