.driver-popover * {
  font-family: @font-family !important;
}

.driver-popover-navigation-btns button {
  padding: 4px 8px !important;
  font-size: 14px !important;

  text-shadow: none !important;
  &.driver-popover-next-btn {
    color: @b-0 !important;
    background: @primary-2 !important;
    border-color: @primary-2 !important;
  }
}
