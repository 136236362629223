.@{class-prefix}-wmr-auth-layout{
  margin-top: 56px;
}

.@{class-prefix}-wmr-layout{
  overflow-x: hidden;
  margin-top: 56px;
  min-height: calc(100vh - 56px);
}

.@{class-prefix}-wmr-layout-non-pwa{
  margin-top: 120px;
}