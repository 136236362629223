// CategoryOfTheWeek
.@{class-prefix}-categoriesOfTheWeek {
  .@{class-prefix}-img-container {
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 7px;
    margin-right: 8px;
  }
}

// Credit Card
.@{class-prefix}-credit-card {
  &[type="primary"] {
    background: @primary-1;
  }

  &[type="danger"] {
    background: @danger-1;
  }

  &[type="dark"] {
    background: @dark-bg;
  }

  &[type="warning"] {
    background: @warning-1;
  }

  .ant-progress-inner {
    background: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-progress-bg {
    background: @b-0;
  }

  &-circle {
    min-width: 158px;
    height: 158px;
    transform: translate(2px, -18px);
    pointer-events: none;
  }
}

// eCommerceCardOne
.@{class-prefix}-eCommerceCardOne {
  img {
    max-height: 200px;
  }

  .ant-rate {
    &.@{class-prefix}-rate {
      width: 32px !important;
      height: 32px !important;
      font-size: 16px;
      line-height: 0;
    }
  }

  &-text-overlay {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// BestSellingCategories
.@{class-prefix}-bestSellingCategories {
  .@{class-prefix}-img-container {
    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 7px;
    margin-right: 8px;
  }
}

// WelcomeCardOne
.@{class-prefix}-welcomeCard {
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;

  .@{class-prefix}-dot-1 {
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    top: -58%;
    left: -41%;
    border-radius: 50%;

    @media @lg {
      left: -20%;
    }

    @media @xs {
      left: -40%;
    }
  }

  .@{class-prefix}-img-1 {
    position: absolute;
    top: 50%;
    transform: translate(30%, -50%);
    right: 0;

    @media @xs {
      display: none;
    }
  }

  .@{class-prefix}-img-2 {
    position: absolute;
    top: 50%;
    transform: translate(30%, -50%);
    right: 0;

    @media @xs {
      display: none;
    }
  }

  .@{class-prefix}-img-3 {
    position: absolute;
    top: 50%;
    transform: translate(30%, -50%);
    right: 0;

    @media @xs {
      display: none;
    }
  }

  .@{class-prefix}-img-4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -14%;

    @media @lg {
      right: -10%;
    }

    @media @xs {
      display: none;
    }
  }

  .@{class-prefix}-img-5 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -14%;

    @media @lg {
      right: -10%;
    }

    @media @xs {
      display: none;
    }
  }
}

// UpgradePlanCardOne
.@{class-prefix}-upgradePlanCardOne {
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;

  @media @sm {
    &-bg {
      // background-position: 530px center !important;
    }

    .ant-col {
      flex: 0 0 100% !important;
    }
  }

  .@{class-prefix}-dot-1 {
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: -1;
    bottom: -90%;
    left: -20%;
    border-radius: 50%;

    @media @xs {
      display: none;
    }
  }

  .@{class-prefix}-dot-2 {
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: -2;
    top: -105%;
    right: -21%;
    border-radius: 50%;

    @media @xs {
      left: -40%;
    }
  }

  .@{class-prefix}-dot-3 {
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: -1;
    top: -58%;
    right: -29%;
    border-radius: 50%;

    @media @xl {
      right: -41%;
    }

    @media @xs {
      left: -40%;
      display: none;
    }
  }
}

// SocialMedia
.@{class-prefix}-social-media-logo-wrapper {
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: center;
  border-radius: @border-radius-base;
  position: relative;

  .@{class-prefix}-social-media-logo {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.@{class-prefix}-project-table-card {
  &.@{class-prefix}-analytics-project-table-card {
    .ant-table-tbody > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.@{class-prefix}-project-table-card {
  .@{class-prefix}-img-container {
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 7px;
    margin-right: 8px;
  }

  .ant-table-thead {
    > tr {
      > th {
        text-align: left;
        background: none !important;
        border-bottom: none;

        &:before {
          visibility: hidden;
        }
      }
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-tbody {
    > tr.ant-table-row {
      &:hover {
        > td {
          background: #fff;
        }
      }
    }
  }
}

.@{class-prefix}-project-ecommerce-table-card {
  .ant-table-thead {
    > tr {
      > th {
        background: none !important;
        border-bottom: none;

        &:before {
          visibility: hidden;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .ant-table-tbody > tr {
    > td {
      border-bottom: none;
      padding-top: 15px;
      padding-bottom: 15px;

      &:first-child {
        padding-left: 0;
      }
    }

    &:first-child {
      > td {
        padding-top: 0px;
      }
    }

    &:last-child {
      > td {
        border: none;
      }
    }
  }

  .ant-table-tbody {
    > tr.ant-table-row {
      &:hover {
        > td {
          background: #fff;
        }
      }
    }
  }
}

.@{class-prefix}-order-table-card {
  .ant-table-thead {
    tr {
      th {
        background: none !important;
        border: none;

        &:before {
          visibility: hidden;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        > td {
          background: none;
        }
      }

      td {
        border: none;
        padding: 7px 16px;

        .@{class-prefix}-img-container {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
