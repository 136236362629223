.@{class-prefix}-select-box-item {
  > input:checked {
    ~ label {
      .ant-card {
        border-color: @primary-1 !important;
        background-color: @primary-4;

        .@{class-prefix}-title {
          color: @primary-1 !important;
        }

        .@{class-prefix}-caption{
          color: @primary-1 !important;
        }
      }
    }
  }
}

.@{class-prefix}-select-box-item {
  height: 100% !important;
    .@{class-prefix}-title {
        font-size: 13px;
        line-height: 1.4;
        font-weight: 600;
      }
}

.@{class-prefix}-select-box-item {
  > input:disabled {
    ~ label {
      .ant-card {
        background-color: @b-20;
      }
    }
  }
}