.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    text-shadow: none;
  }
}

.@{class-prefix}-tabs-full {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block; /* centers text inside tabs */
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
}

.@{class-prefix}-tabs-solid .ant-tabs-nav-list {
  background: @b-0;
}

body.dark {
  .@{class-prefix}-tabs-solid .ant-tabs-nav-list {
    background: @dark-90;
  }
}
