

.@{class-prefix}-topbar{
    height: 56px;
    padding: 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .@{class-prefix}-wmr-layout-non-pwa{
    .@{class-prefix}-topbar{
      top: 64px !important;
    }
}


  .@{class-prefix}-topbar-pwa{
    height: 64px;
    padding: 8px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
    border-radius: 0;
  }
  
  .@{class-prefix}-topbar-title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }
  