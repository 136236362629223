@media @sm {
  .@{class-prefix}-topup-amount span {
    font-size: 12px;
  }

  .@{class-prefix}-product-title{
    font-size: 12px;
    line-height: 1.2;
  }
  .@{class-prefix}-card-sku{
    .@{class-prefix}-caption{
      font-size: 12px;
    }
  }
}

@media @xs {
  .@{class-prefix}-card-sku{
    .@{class-prefix}-caption{
      font-size: 10px;
    }
  }
}

@media @md {
  .@{class-prefix}-md-text-center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.@{class-prefix}-step-number{
  width: 32px;
  height: 32px;
  border-radius: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  color: @b-0;
}

.@{class-prefix}-overflow-none{
  overflow: none !important;

  &:hover{
    overflow-x: scroll;
      &::-webkit-scrollbar {
          width: unset !important;
          height: unset !important;
      }
  
      &::-webkit-scrollbar-thumb {
        background: unset !important;
        -webkit-border-radius: unset !important;
        -webkit-box-shadow: unset !important
      }
    }
  
    &::-webkit-scrollbar {
      background: unset !important;
      margin-top: unset !important
    }
  
    &::-webkit-scrollbar-thumb {
      background: unset !important;
      -webkit-border-radius: unset !important;
      -webkit-box-shadow: unset !important
    }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px !important;
}
.ant-upload-picture-card-wrapper.vc-w-100{
  .ant-upload-list-picture-card-container{
    width: 100%;
  }
  .ant-upload{
    width: 100%;
  }
}
body.dark{

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: @b-0;
  }

  .@{class-prefix}-text-onboarding{
    p, span{
      color:#fff !important
    }
  }

  .@{class-prefix}-wysiwyg-output{
    p, span, h1, h2, h3, h4, h5, h6, div{
      color:#fff !important;
      margin-bottom: 4px;
    }
  }
  
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}

.@{class-prefix}-wrap-text{
  word-wrap: break-word;
  word-break: break-all;
}

.@{class-prefix}-not-wrap-text{
  word-wrap: unset !important;
  word-break: unset !important;
}

.@{class-prefix}-wysiwyg-output{
  p, span, h1, h2, h3, h4, h5, h6, div, a{
    // word-wrap: break-word;
    word-break: break-word;
  }

  h1{
    font-size: 24px;
  }

  h2{
    font-size: 20px;
  }

  h3{
    font-size:18px;
  }

  h4{
    font-size: 16px ;
  }

  h5{
    font-size: 14px;
  }
}

.@{class-prefix}-carousel-tier{
  .react-multiple-carousel__arrow{
    top: 10% !important;
  }
}
.react-multiple-carousel__arrow{
  z-index: 99 !important;
}

.@{class-prefix}-whatsapp-parent {
  position: fixed;
  right: 32px;
  bottom: 40px;
  padding: 8px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  // display: block;
  border-radius: 50px;
  background-color: @success-1;
  color:#fff;
  box-shadow: inset 0 0 0 2px #fff;
  z-index: 999;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;

  &:hover {
      border-radius: 16px 50px 50px 50px;
  }
}

@media @sm {
  .ant-picker-panels {
    flex-direction: column !important;
  }
}

.@{class-prefix}-bg-color-black-4{
  background: @b-40;
}

.@{class-prefix}-text-color-black-1{
  color: @b-100;
}


body.dark{
  .@{class-prefix}-bg-color-dark-black{
    background: @dark-90 !important;
    color: @b-10 !important;
  }
}

.antd-country-phone-input{
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border: none !important;
  }
  
}

body.dark{
  color:@dark-0;
  
  .ant-skeleton-active .ant-skeleton-title::after, .ant-skeleton-active .ant-skeleton-paragraph > li::after, .ant-skeleton-active .ant-skeleton-avatar::after, .ant-skeleton-active .ant-skeleton-button::after, .ant-skeleton-active .ant-skeleton-input::after, .ant-skeleton-active .ant-skeleton-image::after{
    background: linear-gradient(90deg, @dark-90 25%, @dark-100 37%, @dark-80 63%)
  }
  
  .ant-card{
    color:@dark-0;
  }
  
  .ant-modal-body{
    color:@dark-0;
  }
  
  .ant-statistic-title{
    color:@dark-0;
  }

  .@{class-prefix}-select-box-item {
    > input:disabled {
      ~ label {
        .ant-card {
          background-color: @dark-80 !important;
        }
      }
    }
  }
}

.ant-notification {
  z-index: 1020 !important;
}
.antd-country-phone-input .ant-select{
  min-width: 110px !important;
}


.@{class-prefix}-custom-tabs{
  width: 100%;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  
  &.active{
    color: @primary-1;
    -webkit-transition: color 1s ease;
    transition: color 1s ease;
    border-bottom : 1px solid @primary-1;
  }
}

.@{class-prefix}-wysiwyg-onboarding{
  h1,h2,h3,h4,h5,h6{
    display: flex;
    align-items: center;
    margin: 16px 0;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    border-top: 0;

    &:after{
      margin-left: 8px;
      position: relative;
      width: 90%;
      border-top: 1px solid red;
      border-top-color: inherit;
      border-bottom: 0;
      transform: translateY(50%);
      content: " ";
    }
  }
}

.@{class-prefix}-wb-break-word{
  word-break: break-word;
}

body.dark .ant-table-tbody > tr.ant-table-cell-row:hover > td,
body.dark .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: @dark-70;
}

// React Quill Dark
body.dark {
  .ql-snow.ql-toolbar button{
      color: fff;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      background-color: #000!important;
  }
  .ql-snow .ql-picker {
      color: #fff !important;
  }

  button .ql-stroke,
  .ql-picker-label, .ql-stroke {
  fill: none;
  stroke: #fff !important;
  }

  .ql-active .ql-stroke {
  fill: none;
  stroke: #fff!important;
  }

  button:hover .ql-fill,
  .ql-picker-label:hover .ql-fill {
  fill: #fff!important;
  stroke: none;
  }

  .ql-active .ql-fill {
  fill: #fff!important;
  stroke: none;
  }
  .ql-editor{
      color: #fff !important;
  }
}

.btn-ai:not([disabled]) {
  background: linear-gradient(135deg, #CEEAFF, #EFEBFF);
  -webkit-transition:all .2s ease-in;
  transition:all .2s ease-in;
  border: 0;

  &:hover {
    background: linear-gradient(170deg, #CEEAFF, #EFEBFF);
    opacity: .9;
  }
}